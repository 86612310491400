import { cx } from '@emotion/css';
import type { FC } from 'react';
import React, { memo } from 'react';
import { SizeType } from '../../constants/sizes';
import { assertUnreachable } from '../../shared/assertUnreachable';
import type { BaseComponentProps, ValueOf } from '../../shared/types';
import './spinner.scss';

export const SpinnerColors = {
  BLACK: 'black',
  GRAY: 'gray',
  WHITE: 'white',
  YELLOW: 'yellow',
} as const;

export type SpinnerColor = ValueOf<typeof SpinnerColors>;

export type SpinnerSize =
  | typeof SizeType.SMALL
  | typeof SizeType.REGULAR
  | typeof SizeType.LARGE
  | typeof SizeType.X_LARGE;

const getSpinnerSizeClass = (size: SpinnerSize) => {
  switch (size) {
    case SizeType.SMALL:
      return 'sds-spinner-small';
    case SizeType.LARGE:
      return 'sds-spinner-large';
    case SizeType.X_LARGE:
      return 'sds-spinner-x-large';
    case SizeType.REGULAR:
      return undefined;
    default:
      return assertUnreachable(size);
  }
};

const getSpinnerColorClass = (color: SpinnerColor) => {
  switch (color) {
    case SpinnerColors.BLACK:
      return 'sds-spinner-black';
    case SpinnerColors.GRAY:
      return 'sds-spinner-gray';
    case SpinnerColors.WHITE:
      return 'sds-spinner-white';
    case SpinnerColors.YELLOW:
      return 'sds-spinner-yellow';
    default:
      return assertUnreachable(color);
  }
};

export interface SpinnerProps extends BaseComponentProps {
  /**
   * The color of the spinner
   * @default 'gray'
   */
  color?: SpinnerColor;
  /**
   * The size of the spinner
   * @default 'regular'
   */
  size?: SpinnerSize;
  /**
   * Whether to provide a right margin
   * @default false
   */
  withMargin?: boolean;
}

const Spinner: FC<SpinnerProps> = memo(
  ({
    color = SpinnerColors.GRAY,
    size = SizeType.REGULAR,
    style = {},
    withMargin = false,
    className,
    'data-testid': dataTestid,
  }) => {
    const spinnerClassName = cx(
      'sds-spinner',
      getSpinnerSizeClass(size),
      getSpinnerColorClass(color),
      {
        'sds-spinner-right-margin': withMargin,
      },
      className,
    );

    return <div data-testid={dataTestid} style={style} className={spinnerClassName} />;
  },
);

Spinner.displayName = 'Spinner';

export default Spinner;
