/**
 * Use in control flow to assert cases are handled exhaustively e.g
 *
 *    const SpinnerColors = {
 *      BLACK: 'black',
 *      GREY: 'grey',
 *      WHITE: 'white',
 *      YELLOW: 'yellow',
 *    } as const;
 *
 *    type SpinnerColor = ValueOf<typeof SpinnerColors>;
 *
 *    const getSpinnerColorClass = (color: SpinnerColor) => {
 *      switch (color) {
 *        case SpinnerColors.BLACK:
 *          return 'sds-spinner-black';
 *        case SpinnerColors.GREY:
 *          return 'sds-spinner-grey';
 *        case SpinnerColors.WHITE:
 *          return 'sds-spinner-white';
 *        case SpinnerColors.YELLOW:
 *          return 'sds-spinner-yellow';
 *        default:
 *          return assertUnreachable(color);
 *     }
 *   };
 */
export const assertUnreachable = (value: never): never => {
  throw new Error("Didn't expect to get here for value: " + value);
};
